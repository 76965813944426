/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useSelector} from 'react-redux'
import { useStaticQuery, graphql } from "gatsby"
import { Authenticator,SignUp,AuthPiece,Greetings } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports'; 
import { I18n } from 'aws-amplify';
import Navbar from './dormis/navbar/navbar'
import 'react-table-6/react-table.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'microtip/microtip.css'
import 'moment/locale/es'  // without this line it didn't work
import "./layout.css"
import "./barcode.css"


const authScreenLabels = {
es: {
        'Sign In': "Ingresar",
        'Sign Up': "Registrarse",
        'Sign Out': "Salir",
        'Forgot Password': "Se te olvidó tu contraseña",
        'Username': "Usuario",
        'Password': "Contraseña",
        'Change Password': "Cambia la contraseña",
        'New Password': "Nueva contraseña",
        'Email': "Email",
        'Phone Number': "Número de teléfono",
        'Confirm a Code': "Confirmar un código",
        'Confirmation Code': "Código de confirmación",
        'Confirm Sign In': "Confirma el inicio de sesión",
        'Confirm Sign Up': "Confirma el registro",
        'Back to Sign In': "Volver a iniciar sesión",
        'Send Code': "Enviar código",
        'Confirm': "Confirmar",
        'Resend a Code': "Reenviar un código",
        'Resend Code': "Reenviar código",
        'Submit': "Enviar",
        'Skip': "saltar",
        'Verify': "verificar",
        'Verify Contact': "Verificar contacto",
        'Code': "Código",
        'Account recovery requires verified contact information': "La recuperación de la cuenta requiere información de contacto verificada",
        'User does not exist': "El usuario no existe",
        'User already exists': "El usuario ya existe",
        'Incorrect username or password': "Nombre de usuario o contraseña incorrectos",
        'Invalid password format': "Formato de contraseña no válido",
        'Invalid phone number format': "Formato de número de teléfono no válido",
        'Sign in to your account': "Inicia sesión en tu cuenta",
        'Forget your password? ': "¿Olvidaste tu contraseña?",
        'Reset password': " Click aqui",
        'No account? ': "¿No tienes una cuenta?",
        'Create account': "Crear cuenta",
        'Create Account': "Crear cuenta",
        'Have an account? ': "¿Ya tienes una cuenta?",
        'Sign in': "Registrarse",
        'Create a new account': "Crea una nueva cuenta",
        'Reset your password': "Restablece tu contraseña",
        'Enter your username': "Introduce tu nombre de usuario",
        'Enter your password': "Introduce tu contraseña",
        'Enter your code': "Introduzca su código",
        'Lost your code? ': "¿Perdiste tu código?",
        'An account with the given email already exists.': "Ya existe una cuenta con el correo electrónico especificado.",
        'Username cannot be empty': "El nombre de usuario no puede estar vacío",
        "Change":"Enviar",
    },
};
I18n.setLanguage('es');
I18n.putVocabularies(authScreenLabels);


Amplify.configure(awsconfig)

class Private extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signedIn'];
  }
  componentDidMount () {
    const script = document.createElement("script");
    script.src = "/dbr.js";
    document.body.appendChild(script);
    const script2 = document.createElement("script");
    script2.src = "/scannerInit.js";
    document.body.appendChild(script2);
}

  showComponent(){
    return(
    <>
      <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
      <main style={{textAlign:"center"}}>{this.props.children}</main>
    </>
  )}
}


const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const isMobile = useSelector(state => state.mainReducer.isMobile)

  return (
    <>
    <Authenticator  theme={{signUpButton: { 'backgroundColor': 'blue', 'borderColor': 'blue' }}} hide={[SignUp,Greetings]}>
      <Private siteTitle={data.site.siteMetadata.title}>
        {children}
      </Private>
    </Authenticator> 
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


